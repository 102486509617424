import { useState } from 'react';
import socialShare from '@utils/socialShare';

const useShare = ({ title, text, link, textToCopy = null }) => {
  const [copied, setCopied] = useState(false);
  const updatedLink = link || window.location.href;

  const handleFBShare = () =>
    socialShare.shareToFB({
      url: updatedLink,
      title
    });

  const handleTwitterShare = () =>
    socialShare.shareToTwitter({
      url: updatedLink,
      text
    });

  const copyToClipboard = () => {
    const navigator = window.navigator.clipboard.writeText(textToCopy ?? link);
    return navigator?.then(() => setCopied(true));
  };

  return {
    copied,
    handleFBShare,
    handleTwitterShare,
    copyToClipboard
  };
};

export default useShare;
