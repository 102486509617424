import useFetch from '@hooks/useFetch';
import { emptyObject } from '@constants/empty';

export const getKey = (eventKey) =>
  `v1/uplus/prodigy_user/details/?prodigy_key=${eventKey}`;

const useScholarshipEventDetails = (
  eventKey,
  options = {
    revalidateOnMount: false,
    fallbackData: undefined
  }
) => {
  const key = getKey(eventKey);

  const { data: prodigyDetails, ...rest } = useFetch(
    key,
    {},
    {
      revalidateOnMount: options.revalidateOnMount,
      fallbackData: options.fallbackData
    }
  );

  return {
    key,
    prodigyDetails: prodigyDetails || emptyObject,
    ...rest
  };
};

export default useScholarshipEventDetails;
