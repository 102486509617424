import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import styleHelper from '@utils/styleHelper';
import { Fonts, Spacings, breakpoints } from '@styles/index';

const H6 = styled.h6`
  font-weight: ${Fonts.SEMIBOLD};
  font-size: ${Fonts.H6};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  margin: ${Spacings.SPACING_0B};
  color: ${({ color }) => color};
  @media only screen and (max-width: ${breakpoints.tablet}) {
    font-size: 12px;
  }
  ${({ lineClamp }) =>
    lineClamp ? styleHelper.lineClampStyle(lineClamp) : null}
`;

export default H6;

H6.propTypes = {
  color: PropTypes.string,
  lineClamp: PropTypes.number
};

H6.defaultProps = {
  color: 'var(--color-text-primary)',
  lineClamp: null
};
