import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IconButton from '@base/IconButton/IconButton';
import textHelper from '@utils/textHelper';
import ChevronLeft from '@icon/ChevronLeft';
import ChevronRight from '@icon/ChevronRight';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import deviceHelper from '@utils/deviceHelper';
import LeftStickyColumn from './LeftStickyColumn';
import BlocksWrapper from './BlocksWrapper';
import { Spacings } from '../../../styleConstants';

const RightColumn = styled.div`
  flex: 2;
  @media only screen and (max-width: 540px) {
    width: 100%;
    flex: 1;
  }
`;

const EducatorsContainer = styled.div`
  visibility: ${({ customWidth }) => (customWidth ? 'visible' : 'hidden')};
  width: ${({ customWidth }) => (customWidth ? `${customWidth}px` : 0)};
`;

const InnerContainer = styled.div`
  width: ${({ customWidth }) => (customWidth ? `${customWidth + 16}px` : 0)};
  padding-right: 16px;
  overflow: hidden;
  @media only screen and (max-width: 540px) {
    width: ${({ customWidth }) => (customWidth ? `${customWidth}px` : 0)};
    padding-right: 0px;
  }
`;

const Educators = styled.div`
  display: flex;
  width: 250%;
  transform: ${({ pos }) =>
    (pos && `translate3d(calc(${-20 * pos}%), 0, 0)`) || 'initial'};
  transition: transform 400ms ease-in;
  @media only screen and (max-width: 540px) {
    width: 500%;
  }
`;

const EducatorColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 16px;
  margin-bottom: 16px;
  visibility: ${({ column, pos }) =>
    column > pos + 1 || column < pos ? 'hidden' : 'visible'};
  transition: visibility 400ms ease-in;
  @media only screen and (max-width: 540px) {
    padding-left: 0px;
    visibility: ${({ column, pos }) => (column !== pos ? 'hidden' : 'visible')};
  }
`;

const EducatorCard = styled.div`
  display: flex;
  align-items: center;
  background: var(--color-i-white);
  box-shadow: ${boxShadowGenerator()};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
`;

const Thumbail = styled.img`
  height: 108px;
  width: 108px;
  border-radius: 8px;
  margin-right: 24px;
  object-fit: cover;
  user-select: none;
  user-drag: none;
`;

const EducatorName = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: var(--color-i-black);
  @media only screen and (max-width: 540px) {
    text-align: left;
    font-size: 16px;
  }
`;

const SubText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--color-i-black);
  @media only screen and (max-width: 540px) {
    text-align: left;
    font-size: 12px;
  }
`;

const GoalText = styled(SubText)`
  margin-top: 8px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ControlButton = styled(IconButton)`
  height: ${Spacings.SPACING_10B};
  width: ${Spacings.SPACING_10B};
  margin-left: ${Spacings.SPACING_3B};
  opacity: ${({ available }) => (available ? 1 : 0.5)};
  cursor: ${({ available }) => (available ? 'pointer' : 'auto')};
`;

const bestEducators = [
  {
    name: 'Manoj Chauhan',
    username: 'mcsir',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/718968181ed04b2c98bcd6521ef0b964.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '54k'
  },
  {
    name: 'DC Pandey',
    username: 'dcp130267',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/e85552e4f39541be88cf6494b7f97b87.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '20k'
  },
  {
    name: 'Neeraj Kumar Chaudhary',
    username: 'nkcsir',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/bc903bbc856c414c93be73417991a1de.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '6k'
  },
  {
    name: 'Mohammad Kashif Alam',
    username: 'kcf521',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/25d75fc1eb2d49058ade0a67cb37a032.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '13k'
  },
  {
    name: 'Vipul Mehta',
    username: 'vipulmehta-6754',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/3595aa320da2422c88d94afccfee9f4b.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '1k'
  },
  {
    name: 'Paaras Thakur',
    username: 'royalmaddy07',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/dd2c4d6a892549c7b3888cc2a1253093.png?q=100&w=512',
    goal: 'IIT JEE',
    followers: '47k'
  },
  {
    name: 'Amarnath Anand',
    username: 'unacademy-user-ewzeuvc8un5y',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/71fb8b348b5745ce824326a121e6e641.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '1k'
  },
  {
    name: 'Amit Gupta',
    username: 'mitgupt-1395',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/635567a3595e44ecab51f4dc55e0435a.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '1k'
  },
  {
    name: 'Akhilesh Kanther',
    username: 'akhilesh.kanther-2838',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/db09e87855d640f1898ce44116d7e016.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '1k'
  },
  {
    name: 'Surendra K Mishra',
    username: 'surendramish-5026',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/3818e69a117a4214b99da6b955ea768a.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '1k'
  },
  {
    name: 'Vishal Joshi',
    username: 'vishu.joshi2009-7',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/a8a992a9cac3487b88102fc9f08c4f7d.jpg?q=100&w=512',
    goal: 'IIT JEE',
    followers: '1k'
  },
  {
    name: 'Prateek Jain',
    username: 'physicsbyprateekjain',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/e0d0d3f0b168454c85fc5bacc6941c60.jpg?q=100&w=512',
    goal: 'NEET UG',
    followers: '15k'
  },
  {
    name: 'Ramesh Sharda',
    username: 'rameshsharda20-8673',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/a083b76895e34c69b9b533ca2e161ea5.jpg?q=100&w=512',
    goal: 'NEET UG',
    followers: '4k'
  },
  {
    name: 'Pradeep Chittora',
    username: 'chittora_pradeep-2788',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/90831573c76445c4b3c92d78bbea25ee.jpg?q=100&w=512',
    goal: 'NEET UG',
    followers: '2k'
  },
  {
    name: 'Sunil Lakhotia',
    username: 'sunil.lakhotia170-543',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/3665ede72ff34428b2165402977abb1e.jpg?q=100&w=512',
    goal: 'NEET UG',
    followers: '1k'
  },
  {
    name: 'Dr Raj Kumar Singh',
    username: 'cityofbiology',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/a7ff845fb13f41f28fbb7d0bb82db69b.jpg?q=100&w=512',
    goal: 'NEET UG',
    followers: '6k'
  },
  {
    name: 'Dr Praveen Kumar Agrawal',
    username: 'biologybypkagrawal-6909',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/bf03cc34b0084fab904523de4f204891.jpg?q=100&w=512',
    goal: 'NEET UG',
    followers: '65k'
  },
  {
    name: 'Pawan Wagh',
    username: 'mathteacher.pawanwagh-9996',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/user/c86731c7580141e0975c805200b4f817.jpg?q=100&w=512',
    goal: 'Foundation & NTSE',
    followers: '4k'
  },
  {
    name: 'Ravi Ahlawat',
    username: 'ahlawat.09',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/course/242ab978e40b4298814f41bf25d94cbe.png?q=100&w=512&fm=webp',
    goal: 'Foundation & NTSE',
    followers: '1k'
  },
  {
    name: 'Shailee Kaushal',
    username: 'kaushalshaillee',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/course/42cb9987bc234ce9b6150f0d89b2558c.png?q=100&w=512&fm=webp',
    goal: 'Foundation & NTSE',
    followers: '26k'
  },
  {
    name: 'Anoushka Sinha',
    username: 'anoushkaa-6530',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/course/802fb6f8c6e84cbd8cbf29736697f31b.png?q=100&w=512&fm=webp',
    goal: 'Foundation & NTSE',
    followers: '1k'
  },
  {
    name: 'Shivanshu Atrey',
    username: 'shivanshuatrey1982',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/course/0ec87db4957943ea9ed6a9c1099cdfe9.png?q=100&w=512&fm=webp',
    goal: 'Foundation & NTSE',
    followers: '4k'
  },
  {
    name: 'Akshay Tyagi',
    username: 'pochinki',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/course/cdaa7e29ade54f3d9f7fb5d85b1d7f84.png?q=100&w=512&fm=webp',
    goal: 'Foundation & NTSE',
    followers: '2k'
  },
  {
    name: 'Sangeeta Khatri',
    username: 'sangeetakhatri',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/course/016cae45119240d5a3bea35f4b29a642.png?q=100&w=512&fm=webp',
    goal: 'Foundation & NTSE',
    followers: '1k'
  },
  {
    name: 'Raju Ranjan Singh',
    username: 'dr.rishabh.singh',
    thumbnail:
      'https://edge.uacdn.net/static/thumbnail/course/0ec87db4957943ea9ed6a9c1099cdfe9.png?q=100&w=512&fm=webp',
    goal: 'Foundation & NTSE',
    followers: '4k'
  }
];
const bestEducatorsList1 = bestEducators.slice(0, 5);
const bestEducatorsList2 = bestEducators.slice(5, 10);
const bestEducatorsList3 = bestEducators.slice(10, 15);
const bestEducatorsList4 = bestEducators.slice(15, 20);
const bestEducatorsList5 = bestEducators.slice(20, 25);

const EducatorsList = ({ enrollNow, disable }) => {
  const rightColumn = useRef(null);
  const [rightColumnWidth, setRightColumnWidth] = useState(0);
  const [pos, setPos] = useState(0);

  useEffect(() => {
    setRightColumnWidth(
      rightColumn.current ? rightColumn.current.offsetWidth : 0
    );
  }, []);

  const renderEducators = (educatorsList, column) => {
    return (
      <EducatorColumn column={column} pos={pos}>
        {educatorsList.map((educator, i) => {
          return (
            <EducatorCard key={`educator-${i + 1 + column}`}>
              <Thumbail
                src={`https://quark.uacdn.net/thumbnails/${educator.username}.png`}
              />
              <div>
                <EducatorName>{educator.name}</EducatorName>
                <GoalText>{educator.goal}</GoalText>
                <SubText>
                  {textHelper.pluralize(educator.followers, 'follower')}
                </SubText>
              </div>
            </EducatorCard>
          );
        })}
      </EducatorColumn>
    );
  };

  const handleNext = () => {
    if (pos < 4) setPos(pos + 1);
  };

  const handlePrev = () => {
    if (pos > 0) setPos(pos - 1);
  };

  const controlButtons = () => {
    const prevAvailable = pos > 0;
    let nextAvailable = pos < 3;
    if (pos === 3) {
      if (deviceHelper.isMobileDevice()) {
        nextAvailable = true;
      }
    }
    return (
      <Buttons>
        <ControlButton
          onClick={prevAvailable && handlePrev}
          available={prevAvailable}
        >
          <ChevronLeft size="24px" />
        </ControlButton>
        <ControlButton
          onClick={nextAvailable && handleNext}
          available={nextAvailable}
        >
          <ChevronRight size="24px" />
        </ControlButton>
      </Buttons>
    );
  };

  if (disable) return null;

  return (
    <BlocksWrapper>
      <LeftStickyColumn
        icon="cap"
        heading="Curated by the best"
        subheading="Questions hand-picked by our top educators to help you evaluate your exam preparation"
        enrollNow={enrollNow}
      />
      <RightColumn ref={rightColumn}>
        {rightColumnWidth && (
          <EducatorsContainer customWidth={rightColumnWidth}>
            <InnerContainer customWidth={rightColumnWidth}>
              <Educators pos={pos}>
                {renderEducators(bestEducatorsList1, 0)}
                {renderEducators(bestEducatorsList2, 1)}
                {renderEducators(bestEducatorsList3, 2)}
                {renderEducators(bestEducatorsList4, 3)}
                {renderEducators(bestEducatorsList5, 4)}
              </Educators>
            </InnerContainer>
          </EducatorsContainer>
        )}
        {controlButtons()}
      </RightColumn>
    </BlocksWrapper>
  );
};

export default EducatorsList;

EducatorsList.propTypes = {
  enrollNow: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired
};
