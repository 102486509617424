/* eslint-disable @next/next/no-img-element */
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import PropTypes from 'prop-types';
import { getImage } from '@utils/imageHelper';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import H4 from '@base/H4/H4';
import H6 from '@base/H6/H6';
import Divider from '@base/Divider/Divider';
import Button from '@base/Button/Button';
import Play from '@icon/Play';
import { Spacings } from '@styles/index';
import { getDay, getMonthString, getYear } from '@utils/dates';

const Wrapper = styled.div`
  background: var(--color-base-1);
  width: 100%;
  box-shadow: ${boxShadowGenerator()};
  border-top-width: ${Spacings.SPACING_2B};
  border-top-color: var(--color-i-blue);
  border-top-style: solid;
  border-radius: ${Spacings.SPACING_2B};
  padding: ${Spacings.SPACING_6B} ${Spacings.SPACING_6B} ${Spacings.SPACING_8B};
  margin-bottom: ${Spacings.SPACING_4B};
  display: flex;
  flex-direction: column;
`;

const TestName = styled(H4)`
  @media only screen and (max-width: 540px) {
    text-align: left;
  }
`;

const Date = styled.p`
  margin-top: 8px;
  margin-bottom: 24px;
  color: ${({ liveTag }) =>
    liveTag ? 'var(--color-i-red)' : 'var(--color-i-black)'};
  padding-right: ${({ liveTag }) => liveTag && '4px'};
  font-weight: ${({ liveTag }) => liveTag && '600'};
  @media only screen and (max-width: 540px) {
    text-align: left;
  }
`;

const PulsatingDot = keyframes`
  0% { box-shadow: ${boxShadowGenerator({
    yOffset: 0,
    blur: 0,
    color: 'rgba(235,87,87, 0.4)'
  })}; }
  70% { box-shadow: ${boxShadowGenerator({
    yOffset: 0,
    blur: 0,
    spread: '10px',
    color: 'rgba(235,87,87, 0)'
  })}; }
  100% {
    box-shadow: none;
  }
`;

export const Live = styled.div`
  margin-right: 12px;
  margin-top: 16px;
  border-radius: 50%;
  box-shadow: ${boxShadowGenerator({
    yOffset: 0,
    blur: 0,
    color: 'rgba(235,87,87, 0.4)'
  })};
  animation: ${PulsatingDot} 2s infinite;
  :hover {
    animation: none;
  }
  height: 4px;
  width: 4px;
  background-color: var(--color-i-red);
`;

const KeyPoints = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`;

const Point = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  img {
    height: 32px;
    width: 32px;
    margin-right: 16px;
    @media only screen and (max-width: 540px) {
      height: 24px;
      width: 24px;
    }
  }
  h6 {
    @media only screen and (max-width: 540px) {
      font-size: 12px;
      font-weight: normal;
      text-align: left;
    }
  }
`;

const ProdigyTestCard = ({ data, onClickEnroll, isEnrolled }) => {
  const handleOnClickEnroll = () => {
    if (onClickEnroll) {
      onClickEnroll(!data.isEnrolled, data.uid, data);
    }
  };

  const beginTest = () => {
    window.open(data.permalink);
  };

  const date = new window.Date(data.date);

  return (
    <Wrapper>
      <TestName>{data.name}</TestName>
      {data.isLive ? (
        <div style={{ display: 'flex' }}>
          <Live />
          <Date liveTag>Live</Date>
          <Date>
            {`  •  ${data.isGoingToBeLive ? 'Starts' : 'Started'} at ${
              data.startTime
            }`}
          </Date>
        </div>
      ) : (
        <Date>
          {`${getMonthString(date, 'long')} ${getDay(date)}, ${getYear(date)}`}
        </Date>
      )}
      <Divider />
      <KeyPoints>
        <Point>
          <img src={getImage('prodigy/clock-mono.svg')} alt="clock-mono" />
          <H6>{data.time}</H6>
        </Point>
        <Point>
          <img src={getImage('prodigy/book-mono.svg')} alt="book-mono" />
          <H6>{data.topics}</H6>
        </Point>
        <Point>
          <img
            src={getImage('prodigy/question-mono.svg')}
            alt="question-mono"
          />
          {data.totalCount ? (
            <H6>{`Total of ${data.totalCount} questions`}</H6>
          ) : (
            <H6>{`${data.questionCount} questions per section`}</H6>
          )}
        </Point>
        <Point>
          <img
            src={getImage('prodigy/negative-mono.svg')}
            alt="negative-mono"
          />
          <H6>
            {data.negativeMarks
              ? 'Negative marks for incorrect answers'
              : 'No negative marking'}
          </H6>
        </Point>
      </KeyPoints>
      <Button
        label={
          // eslint-disable-next-line no-nested-ternary
          data.isQuizAvailable
            ? 'Begin test'
            : isEnrolled
            ? 'Enrolled'
            : 'Enroll'
        }
        variant={data.isQuizAvailable || !isEnrolled ? 'filled' : 'hollow'}
        color={data.isQuizAvailable || !isEnrolled ? 'green' : 'black'}
        icon={data.isQuizAvailable ? <Play color="white" /> : null}
        grid={3}
        onClick={data.isQuizAvailable ? beginTest : handleOnClickEnroll}
      />
    </Wrapper>
  );
};

export default ProdigyTestCard;

ProdigyTestCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onClickEnroll: PropTypes.func.isRequired,
  isEnrolled: PropTypes.bool
};

ProdigyTestCard.defaultProps = {
  isEnrolled: false
};
