/* eslint-disable @next/next/no-img-element */
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { getImage } from '@utils/imageHelper';

const LeftColumn = styled.div`
  flex: 1;
  position: sticky;
  height: fit-content;
  top: 80px;
  img {
    height: 48px;
    width: 48px;
  }
  @media only screen and (max-width: 540px) {
    position: relative;
    top: unset;
    margin-bottom: 40px;
  }
`;

const Heading = styled.p`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: var(--color-i-black);
  margin: 16px 0;
  @media only screen and (max-width: 540px) {
    font-size: 24px;
  }
`;

const SubHeading = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-i-black);
  margin-bottom: 40px;
  max-width: 320px;
  @media only screen and (max-width: 540px) {
    margin: 0px auto 24px auto;
  }
`;

const EnrollButton = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: var(--color-i-green);
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  width: fit-content;
  cursor: pointer;
  @media only screen and (max-width: 540px) {
    margin: auto;
  }
`;

const LeftStickyColumn = ({ icon, heading, subheading, enrollNow }) => {
  return (
    <LeftColumn>
      <img src={getImage(`prodigy/${icon}.svg`)} alt="prodigy icon" />
      <Heading>{heading}</Heading>
      <SubHeading>{subheading}</SubHeading>
      <EnrollButton onClick={enrollNow}>Enroll for free</EnrollButton>
    </LeftColumn>
  );
};

export default LeftStickyColumn;

LeftStickyColumn.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  enrollNow: PropTypes.func.isRequired
};
