import styled from '@emotion/styled';
// import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import textHelper from '@utils/textHelper';
import { breakpoints, Spacings } from '@styles/index';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: ${({ $showBanners }) => ($showBanners ? 'unset' : '50%')};
  left: ${({ $showBanners }) => ($showBanners ? '21%' : 'unset')};
  transform: translateX(50%);
  bottom: ${({ mounted }) => (mounted ? 0 : '-100px')};
  opacity: ${({ mounted }) => (mounted ? 1 : 0)};
  transition: all 800ms ease-out 800ms;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    position: relative;
    left: -50%;
    bottom: 0;

    transform: translateX(50%);
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid var(--color-divider);
  background-color: var(--color-i-white);
  border-radius: 12px;
  min-width: 180px;
`;

const Dates = styled.div`
  margin: 24px 27px 0;
  display: flex;
  height: 58px;
  align-items: center;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin: 16px 27px 0;
    height: 48px;
  }
`;

const DateText = styled.span`
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  color: var(--color-i-blue);
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 16px;
  }
`;

const MonthText = styled.span`
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  color: var(--color-i-blue-9);
  margin-top: -3px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

const Stand = styled.div`
  height: 44px;
  width: 24px;
  background-color: var(--color-i-orange-4);
`;

const StandLeft = styled.div`
  width: 16px;
  height: 100%;
  background-color: var(--color-i-orange-1);
`;

const DaysCount = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: -16px;
  height: 34px;
  padding-left: 12px;
  padding-right: 12px;
  background: var(--color-i-pink-4);
  border: 4px solid var(--color-i-pink-3);
  border-radius: 8px;
`;

const DaysCountText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  color: var(--color-i-white);
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 10px;
  }
`;

const testDate = new Date(2020, 5, 13).getTime();
const today = new Date().getTime();
const secondsInDay = 24 * 60 * 60;
const secondsInHr = 60 * 60;

const getRemainingDaysHrs = () => {
  if (new Date().getDate() > 14) {
    return 'HELD ON';
  }
  if (new Date().getDate() === 13 || new Date().getDate() === 14) {
    return 'LIVE NOW';
  }
  const remainingTimeSecs = (testDate - today) / 1000;
  if (remainingTimeSecs < 0) return null;
  const remDays = Math.floor(remainingTimeSecs / secondsInDay);
  const remHrs = Math.round((remainingTimeSecs % secondsInDay) / secondsInHr);
  const remainingTimeTexts = [];
  if (remDays > 0) {
    remainingTimeTexts.push(
      textHelper.pluralize(remDays, 'day')?.toUpperCase()
    );
  }
  if (remHrs > 0) {
    remainingTimeTexts.push(textHelper.pluralize(remHrs, 'hr')?.toUpperCase());
  }
  return remainingTimeTexts.join(Spacings.SPACING_2SPACES);
};

const DateTimer = ({ mounted, headerData, showBanners }) => {
  const remainingTime = getRemainingDaysHrs();
  // const router = useRouter();
  // let heading = '';
  // let subHeading = '';
  // if (router.route === '/prodigy') {
  //   heading = '13 - 14';
  //   subHeading = "JUN'20";
  // } else if (router.route === '/event/UCL') {
  //   heading = 'Sep - Oct';
  //   subHeading = '2020';
  // } else if (router.route === '/event/testseries/aspire') {
  //   heading = '10th & 25th';
  //   subHeading = 'Every Month';
  // }

  return (
    <Wrapper mounted={mounted} $showBanners={showBanners}>
      <Header>
        {remainingTime && remainingTime !== '' && (
          <DaysCount>
            <DaysCountText>TO BE HELD ON</DaysCountText>
          </DaysCount>
        )}
        <Dates>
          <DateText>{headerData.dateHeading}</DateText>
        </Dates>
        <MonthText>{headerData.dateSubHeading}</MonthText>
      </Header>
      <Stand>
        <StandLeft />
      </Stand>
    </Wrapper>
  );
};

export default DateTimer;

DateTimer.propTypes = {
  mounted: PropTypes.bool.isRequired,
  headerData: PropTypes.objectOf(PropTypes.any).isRequired,
  showBanners: PropTypes.bool.isRequired
};
