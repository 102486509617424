import PropTypes from 'prop-types';
import Link from '@base/Link/Link';
import {
  Spacings,
  PRIMARY_BORDER_WIDTH,
  DASHED_BORDER_STYLE
} from '@styles/index';

const anchorStyle = (color) => `
  display: inline-block;
  border-bottom-width: ${PRIMARY_BORDER_WIDTH};
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-style: ${DASHED_BORDER_STYLE};
  border-color: ${color};
  padding-bottom: ${Spacings.SPACING_1B};
  cursor: pointer;
  @media(max-width:70.95em){
    padding-bottom:8px;
  }
  `;

const UnderlineLink = ({
  href,
  as,
  color,
  onClick,
  disabled,
  className,
  children
}) => (
  <Link
    anchorStyle={anchorStyle(color)}
    href={href}
    as={as}
    onClick={onClick}
    disabled={disabled}
    className={className}
  >
    {children}
  </Link>
);

export default UnderlineLink;

UnderlineLink.propTypes = {
  href: PropTypes.string.isRequired,
  as: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

UnderlineLink.defaultProps = {
  as: null,
  onClick: null,
  disabled: false,
  color: 'BLACK',
  className: '',
  children: null
};
