import styled from '@emotion/styled';

const BlocksWrapper = styled.section`
  width: 1136px;
  margin: auto;
  margin-bottom: 160px;
  position: relative;
  display: flex;
  @media only screen and (max-width: 540px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 80px;
  }
`;

export default BlocksWrapper;
