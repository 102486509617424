import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Spacings } from '@styles/index';

const Indicator = styled.div`
  width: ${Spacings.SPACING_6};
  height: ${Spacings.SPACING_6};
  margin: 0 ${Spacings.SPACING_1B};
  border-radius: 50%;
  background: ${({ active, color }) =>
    active ? color || 'var(--color-i-green)' : 'var(--color-divider)'};
  cursor: pointer;
  transition: 400ms;
  &:hover {
    background: ${({ color }) => color || 'var(--color-i-green)'};
  }
`;

const IndicatorItem = ({ active, onClick, color, className }) => (
  <Indicator
    active={active}
    onClick={onClick}
    color={color}
    className={className}
  />
);
export default IndicatorItem;

IndicatorItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired
};
