const shareToFB = ({ url, title = '' }) => {
  if (window) {
    const encodedURL = encodeURIComponent(url);
    const encodedTEXT = encodeURIComponent(title || '');
    const ShareUrl = `http://www.facebook.com/share.php?u=${encodedURL}&title=${encodedTEXT}`;
    window.open(ShareUrl);
  }
};

const shareToTwitter = ({ url, text = '' }) => {
  if (window) {
    const suffixText = ` ${url} @unacademy`;
    const encodedText = text
      ? `${encodeURIComponent(text)} ${encodeURIComponent(suffixText)}`
      : encodeURIComponent(suffixText);
    const ShareUrl = `http://twitter.com/share?text=${encodedText}`;
    window.open(ShareUrl);
  }
};

const handleWhatsappShare = ({ url = '', text = '' }) => {
  if (window) {
    const encodedText = encodeURIComponent(`${text}\n ${url}`);
    const ShareUrl = `whatsapp://send?text=${encodedText}`;
    window.open(ShareUrl);
  }
};

const shareToWhatsappwWeb = ({ url, text = '' }) => {
  if (window) {
    const ShareUrl = `https://web.whatsapp.com/send?url=${url}&text=${text}`;
    window.open(ShareUrl);
  }
};

const shareToTelegram = ({ url, text = '' }) => {
  if (window) {
    const ShareUrl = `https://telegram.me/share/url?url=${url}&text=${text}`;
    window.open(ShareUrl);
  }
};

const socialShare = {
  shareToFB,
  shareToTwitter,
  shareToWhatsappwWeb,
  shareToTelegram,
  handleWhatsappShare
};

export default socialShare;
