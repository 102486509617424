/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import { useState } from 'react';
import Dialog from '@comp/Dialog/Dialog';
import ShareDialog from '@comp/ShareDialog/ShareDialog';
import Button from '@base/Button/Button';
import Share from '@icon/Share';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import { getImage } from '@utils/imageHelper';
import VideoLazyLoad from '@base/VideoLazyLoad/VideoLazyLoad';
import BlocksWrapper from './BlocksWrapper';

const Wrapper = styled(BlocksWrapper)`
  height: 444px;
  background: var(--color-i-white);
  box-shadow: ${boxShadowGenerator()};
  border-radius: 8px;
  padding-left: 80px;
  @media only screen and (max-width: 540px) {
    flex-direction: column-reverse;
    height: auto;
    width: 92%;
    padding: 24px;
    margin: auto 16px;
    margin-bottom: 80px;
  }
`;

const LeftColumn = styled.div`
  max-width: 418px;
  margin: auto 0;
`;

const Heading = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  color: var(--color-i-black);
  margin-bottom: 16px;
  @media only screen and (max-width: 540px) {
    font-size: 24px;
  }
`;

const SubHeading = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: var(--color-i-black);
  margin-bottom: 40px;
  @media only screen and (max-width: 540px) {
    font-size: 16px;
  }
`;

const ShareButton = styled(Button)`
  @media only screen and (max-width: 540px) {
    margin: auto;
  }
`;

const RightColumn = styled.div`
  margin-left: 52px;
  position: relative;
  @media only screen and (max-width: 540px) {
    margin-left: 0;
  }
`;

const ShareVideo = styled.video`
  width: 587px;
  height: 421px;
  @media only screen and (max-width: 540px) {
    width: 100%;
    height: unset;
  }
`;

const ShareBlock = ({ data }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);

  const { title, subTitle, shareLink, shareText } = data || {};

  const onShareClick = () => {
    setShowShareOptions(true);
  };

  return (
    <>
      <Wrapper>
        <LeftColumn>
          <Heading>{title}</Heading>
          <SubHeading>{subTitle}</SubHeading>
          <ShareButton
            grid={3}
            label="Share"
            onClick={onShareClick}
            color="black"
            icon={<Share size="20px" color="var(--color-base-fill)" />}
            variant="hollow"
          />
        </LeftColumn>
        <RightColumn>
          <VideoLazyLoad>
            <ShareVideo loop autoPlay playsInline muted className="lazy-video">
              <source
                data-src={getImage('prodigy/invite-friends.mp4')}
                type="video/mp4"
              />
            </ShareVideo>
          </VideoLazyLoad>
        </RightColumn>
      </Wrapper>
      <Dialog
        onClose={() => setShowShareOptions(false)}
        dialogState={showShareOptions}
      >
        <ShareDialog
          data={{ permalink: shareLink }}
          useDataLink
          twitterText={shareText}
        />
      </Dialog>
    </>
  );
};

export default ShareBlock;
