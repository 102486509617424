/* eslint-disable @next/next/no-img-element */
import {
  forwardRef,
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import deviceHelper from '@utils/deviceHelper';
import { useDispatchToStore } from '@hooks/reduxHooks';
import { toggleEnrolledCourse } from '@actions/courses';
import Carousel from '@comp/Carousel/Carousel';
import FreePlanUnlock from '@comp/FreePlanUnlock/FreePlanUnlock';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import H2 from '@base/H2/H2';
import H4 from '@base/H4/H4';
import { Spacings, Fonts, breakpoints } from '@styles/index';
import { unlockFreePlanFor } from '@constants/index';
import { segment } from '@actions/analytics';
import { GTM_TAP_ENROLL } from '@constants/segment';
import {
  getDay,
  getMonthString,
  getTimeFormatInShortFormWithoutSecs,
  getYear
} from '@utils/dates';
import { getImage } from '@utils/imageHelper';
import BlocksWrapper from './BlocksWrapper';
import ProdigyTestCard from './ProdigyTestCard';
import ProdigyClassCard from './ProdigyClassCard';

const {
  SPACING_4B,
  SPACING_6B,
  SPACING_16B,
  SPACING_0B,
  SPACING_8B,
  SPACING_24B,
  SPACING_2B
} = Spacings;

const StyledCarousel = styled(Carousel)`
  margin: ${SPACING_0B};
`;

const LeftColumn = styled.div`
  flex: 1;
  position: sticky;
  height: fit-content;
  top: 30px;
  img {
    height: 48px;
    width: 48px;
  }
  div:not(:last-child) {
    padding-bottom: ${SPACING_4B};
  }
`;

const Heading = styled(H2)`
  margin: ${SPACING_4B} ${SPACING_0B} 62px ${SPACING_0B};
  font-size: ${Fonts.H2} !important;
`;

const RightColumn = styled.div`
  flex: 2;
  margin-bottom: -${SPACING_8B};
  margin-left: ${SPACING_4B};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    flex: 1;
    margin-left: ${SPACING_0B};
  }
`;

const TestCardsContainer = styled.div`
  margin-left: ${SPACING_24B};
  max-width: 770px;
  height: ${({ customHeight }) =>
    customHeight ? `${customHeight}px` : 'auto'};
  overflow-y: hidden;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    height: auto;
    width: 100%;
    width: ${({ customWidth }) => (customWidth ? `${customWidth}px` : 'auto')};
    margin: ${SPACING_0B};
    overflow-y: visible;
    overflow-x: hidden;
  }
`;

const InnerContainer = styled.div`
  padding: ${SPACING_0B} ${SPACING_4B};
  height: ${({ customHeight }) =>
    customHeight ? `${customHeight}px` : 'auto'};
  transform: ${({ pos, customHeight }) =>
    (pos && `translate3d(0, ${-customHeight * pos}px, 0)`) || 'initial'};
  transition: transform 400ms ease-in;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    height: auto;
    width: ${({ customWidth }) =>
      customWidth ? `${3 * customWidth}px` : 'auto'};
    transform: unset;
    flex-direction: row;
    padding: ${SPACING_0B};
    display: flex;
  }
`;

const GoalTabs = styled.div`
  @media only screen and (max-width: ${breakpoints.mobile}) {
    display: flex;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    /* Chrome and IOS Safari */
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
    div:not(:last-child) {
      margin-right: ${SPACING_2B};
    }
  }
`;

const GoalBlockContainer = styled.div`
  @media only screen and (max-width: ${breakpoints.mobile}) {
    flex: 1 0 auto;
  }
`;

const GoalBlock = styled.div`
  padding: ${SPACING_4B} ${SPACING_16B} ${SPACING_4B} ${SPACING_6B};
  background: ${({ isEnabled }) =>
    isEnabled ? 'var(--color-i-white)' : 'var(--color-i-gray-7)'};
  box-shadow: ${({ isEnabled }) => (isEnabled ? boxShadowGenerator() : 'none')};
  border-radius: ${SPACING_2B};
  height: 64px;
  width: 270px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: fit-content;
    padding: ${SPACING_0B} ${SPACING_4B};
    height: 50px;
    display: flex;
    align-items: center;
    h4 {
      font-size: ${Fonts.H6};
    }
  }
  cursor: pointer;
  transition: all 200ms ease-in;
`;

const TestsContainer = styled.div`
  margin-bottom: ${SPACING_8B};
  height: ${({ customHeight }) =>
    customHeight ? `${customHeight - 32}px` : 'auto'};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    height: auto;
    width: ${({ customWidth }) => (customWidth ? `${customWidth}px` : 'auto')};
    flex: 0 0 auto;
    display: ${({ index, pos, customWidth }) =>
      !customWidth && index !== pos ? 'none' : 'flex'};
    transform: ${({ pos, customWidth }) =>
      (pos && `translate3d(${-customWidth * pos}px, 0, 0)`) || 'initial'};
    overflow-y: visible;
    flex-direction: column;
    margin-bottom: ${SPACING_0B};
  }
  display: ${({ index, pos, customHeight }) =>
    !customHeight && index !== pos ? 'none' : 'flex'};
  visibility: ${({ index, pos }) => (index !== pos ? 'hidden' : 'visible')};
  opacity: ${({ index, pos }) => (index !== pos ? 0 : 1)};
  transition: all 400ms ease-in;
`;

const removeLeadingZero = (value) => {
  const strVal = (value || '').toString();
  return strVal.charAt(0) === '0' ? strVal.slice(1) : strVal;
};

const formatTime = (date) => {
  if (!date) return '';
  const newDate = new Date(date);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  return `${hours > 12 ? hours - 12 : hours}${
    minutes > 0 ? `:${minutes}` : ''
  } ${hours > 11 ? 'P' : 'A'}M`;
};

const formatDate = (date) =>
  `${getMonthString(date, 'long')} ${getDay(date)}, ${getYear(date)}`;

const getTimeForDate = (time, date) => {
  if (!time || !date) return 0;
  const timeSplit = time.split(':');
  const hours = parseInt(removeLeadingZero(timeSplit[0]), 10);
  const minutes = parseInt(removeLeadingZero(timeSplit[1]), 10);
  const newDate = new Date(date);
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate.getTime();
};

const GoalWiseTestSeries = forwardRef(
  (
    { items, id, title, goalData, cardType, data, setOpenAdditiaonalData },
    ref
  ) => {
    const [status, setStatus] = useState({});
    const [freePlanUnlockDrawerState, setFreePlanUnlockDrawerState] =
      useState(false);
    const [enrollClicked, setEnrollClicked] = useState(false);
    const enrollData = useRef();
    const closeFreePlanUnlockDrawer = () => setFreePlanUnlockDrawerState(false);
    const [objectUid, setObjectUid] = useState(null);
    const dispatch = useDispatchToStore();
    const enrollStatuses = useSelector(
      (state) => state.courses.checkEnrollResults
    );
    const me = useSelector((state) => state.auth.me);
    const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
    const isPlatformUnlocked = me.platformUnlockStatus?.is_platform_unlocked;

    useEffect(() => {
      setStatus(enrollStatuses);
    }, [enrollStatuses]);

    const onClickEnroll = useCallback(
      (enroll, uid, classOrTest) => {
        if (enroll) {
          setEnrollClicked(true);
          enrollData.current = {
            enroll,
            uid,
            classOrTest
          };
          const newStatus = { ...status };
          if (isLoggedIn && !isPlatformUnlocked) {
            setObjectUid(uid);
            setFreePlanUnlockDrawerState(true);
          } else {
            if (setOpenAdditiaonalData) {
              setOpenAdditiaonalData();
            }

            dispatch(toggleEnrolledCourse(uid, enroll, true)).then(
              (response) => {
                if (!response?.error && me.username) {
                  setEnrollClicked(false);
                  enrollData.current = null;
                  newStatus[uid] = true;
                  setStatus(newStatus);
                  const isClass = !classOrTest.quizUid;
                  const props = isClass
                    ? {
                        'Class Name': classOrTest.title,
                        'Test Name': classOrTest.title,
                        'Class Uid': classOrTest.uid,
                        'Educator Name': classOrTest.authorName
                      }
                    : {
                        'Test Name': classOrTest.name,
                        'Test Uid': classOrTest.quizUid
                      };
                  const analyticsProps = {
                    ...props,
                    'Start Time': classOrTest.startsAt,
                    'End Time': classOrTest.endsAt,
                    Subject: classOrTest.topics,
                    'Test Series Uid': uid,
                    'GTM Initiative': data.header?.title || '',
                    'Goal Id': '',
                    'Goal Name': ''
                  };
                  segment.track(GTM_TAP_ENROLL, analyticsProps);
                }
              }
            );
          }
        }
      },
      [
        setOpenAdditiaonalData,
        data.header?.title,
        dispatch,
        isLoggedIn,
        isPlatformUnlocked,
        me.username,
        status
      ]
    );

    const freePlanUnlockCallback = () => {
      setTimeout(closeFreePlanUnlockDrawer, 3000);
      onClickEnroll(
        enrollData.current?.enroll,
        enrollData.current.uid,
        enrollData.current.classOrTest
      );
    };

    useEffect(() => {
      if (enrollClicked && isLoggedIn) {
        onClickEnroll(
          enrollData.current?.enroll,
          enrollData.current.uid,
          enrollData.current.classOrTest
        );
      }
    }, [enrollClicked, isLoggedIn, onClickEnroll]);

    const [pos, setPos] = useState(0);
    const [testCardHeight, setTestCardHeight] = useState(0);
    const [testCardWidth, setTestCardWidth] = useState(0);
    const testCardsContainer = useRef(null);
    useEffect(() => {
      setTestCardHeight(
        testCardsContainer.current ? testCardsContainer.current.offsetHeight : 0
      );
      setTestCardWidth(
        testCardsContainer.current ? testCardsContainer.current.offsetWidth : 0
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [enabledGoal, setEnabledGoal] = useState(goalData[0]?.key);
    const selectedGoal = useRef(goalData[0]);
    const onPressGoal = (goal, index) => {
      selectedGoal.current = goal;
      setEnabledGoal(goal.key);
      setPos(index);
    };

    const renderCards = (goal, index) => {
      const Wrapper = deviceHelper.isMobileDevice() ? Fragment : StyledCarousel;

      return (
        <TestsContainer
          index={index}
          key={goal}
          pos={pos}
          customHeight={testCardHeight}
          customWidth={testCardWidth}
        >
          <Wrapper
            showControls
            key="testseries"
            numberOfItems={2}
            gridColumn="span 24"
          >
            {items[goal]?.slice(0, 2)?.map((item) => {
              const nowDate = new Date();
              const testDate = new Date(item.date);
              const startsAt = getTimeForDate(item.startsAt, testDate);
              const endsAt = getTimeForDate(item.endsAt, testDate);
              const startTime = formatTime(startsAt);
              const isQuizAvailable = new Date(startsAt) - nowDate <= 900000;
              const isEnrolled = !!(
                status[item.uid] || status[item.testSeriesUid]
              );
              const formattedTestOrClass = {
                ...item,
                date: formatDate(testDate),
                startsAt,
                endsAt,
                startTime,
                time: `${startTime} - ${formatTime(endsAt)}`,
                duration: getTimeFormatInShortFormWithoutSecs(
                  (endsAt - startsAt) / 1000,
                  ' h',
                  ' m'
                ),
                isEnrolled,
                isQuizAvailable,
                isLive: isQuizAvailable && new Date(endsAt) > nowDate,
                isGoingToBeLive: isQuizAvailable && new Date(startsAt) > nowDate
              };
              const Card =
                cardType === 'test' ? ProdigyTestCard : ProdigyClassCard;
              return (
                <Card
                  key={`testseries-${item.uid}`}
                  data={formattedTestOrClass}
                  onClickEnroll={onClickEnroll}
                  isEnrolled={isEnrolled}
                  gridColumn="span 8"
                />
              );
            })}
          </Wrapper>
        </TestsContainer>
      );
    };

    return (
      <BlocksWrapper ref={ref} id={id}>
        <LeftColumn>
          <img src={getImage('prodigy/date.svg')} alt="date icon" />
          <Heading>{title}</Heading>
          <GoalTabs>
            {goalData.map((goal, index) => {
              if (!items[goal.key]) return null;
              return (
                <GoalBlockContainer key={`goal-${goal.key}`}>
                  <GoalBlock
                    onClick={() => onPressGoal(goal, index)}
                    isEnabled={enabledGoal === goal.key}
                  >
                    <H4
                      color={
                        enabledGoal === goal.key
                          ? 'var(--color-text-primary)'
                          : 'var(--color-text-secondary)'
                      }
                    >
                      {goal.name}
                    </H4>
                  </GoalBlock>
                </GoalBlockContainer>
              );
            })}
          </GoalTabs>
        </LeftColumn>
        <RightColumn ref={testCardsContainer}>
          <TestCardsContainer
            customHeight={testCardHeight}
            customWidth={testCardWidth}
          >
            <InnerContainer
              customHeight={testCardHeight}
              pos={pos}
              customWidth={testCardWidth}
            >
              {typeof window !== 'undefined' &&
                goalData.map((goal, index) => renderCards(goal.key, index))}
            </InnerContainer>
          </TestCardsContainer>
        </RightColumn>
        <FreePlanUnlock
          drawerState={freePlanUnlockDrawerState}
          closeDrawerHandler={closeFreePlanUnlockDrawer}
          objectType={unlockFreePlanFor.COURSE}
          objectUid={objectUid}
          callBack={enrollClicked ? freePlanUnlockCallback : null}
        />
      </BlocksWrapper>
    );
  }
);

GoalWiseTestSeries.displayName = 'GoalWiseTestSeries';

GoalWiseTestSeries.propTypes = {
  items: PropTypes.objectOf(PropTypes.any),
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  goalData: PropTypes.arrayOf(PropTypes.object),
  cardType: PropTypes.oneOf(['test', 'class']).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

GoalWiseTestSeries.defaultProps = {
  id: '',
  goalData: [],
  items: {}
};

export default GoalWiseTestSeries;
