import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import H2 from '@base/H2/H2';
import P2 from '@base/P2/P2';
import P1 from '@base/P1/P1';
import styled from '@emotion/styled';
import Drawer from '@base/Drawer/Drawer';
import TextField from '@base/TextField/TextField';
import Button from '@base/Button/Button';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import { getIsDarkModeOn } from '@stores/selectors/darkModeStatusSelectors';
import UnderlineLink from '@comp/UnderlineLink/UnderlineLink';
import { getImage } from '@utils/imageHelper';
import { segment } from '@actions/analytics';
import { Spacings, Fonts, breakpoints } from '@styles/index';
import {
  sendFreeUnlockDrawerDisplayEvent,
  sendFreePlanUnlockEvent
} from '@events/sendAnalytics';
import {
  FPU_SHOW_DEFAULT,
  FPU_UNLOCKED,
  FPU_UNLOCK_CLICKED
} from '@constants/segment';
import useSelectedGoal from '@api/hooks/topology/useSelectedGoal';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getMe } from '@api/selectors/auth';
import { unlockFreePlan } from '@api/fetchers/auth';

const PLATFORM_UNLOCK_CODE = 'UNACADEMY';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, ${Spacings.SPACING_8B});
  grid-column-gap: ${Spacings.SPACING_4B};
  position: relative;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    grid-column-gap: unset;
    display: flex;
    width: 75vw;
  }
`;

const UnlockButton = styled(Button)`
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 128px;
  }
`;

const Wrapper = styled.div`
  grid-column: span 10;
`;

const StyledTextField = styled(TextField)`
  margin-top: ${Spacings.SPACING_8B};
  background: var(--color-base-1);
  font-weight: ${Fonts.SEMIBOLD};
  font-size: ${Fonts.H6};
  color: var(--color-text-primary);
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
`;

const ErrorMsgText = styled(P2)`
  margin-top: ${Spacings.SPACING_2B};
  font-weight: ${Fonts.SEMIBOLD};
`;

const BottomWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${Spacings.SPACING_8B};
`;

const DefaultCodeTextWrapper = styled.div`
  margin-left: auto;
`;

const LinkText = styled(P1)`
  line-height: ${Fonts.BUTTON_LINE_HEIGHT};
  font-weight: ${Fonts.BOLD};
  margin-bottom: ${Spacings.SPACING_1B};
`;

const DefaultCodeText = styled(P2)`
  line-height: ${Fonts.BUTTON_LINE_HEIGHT};
`;

const lightConfettiURL = 'unlockplan/light-mode-confetti.gif';
const darkConfettiURL = 'unlockplan/dark-mode-confetti.gif';

const ConfettiCont = styled.div`
  width: 532px;
  height: 275.17px;
  background-image: ${({ $isDarkModeOn }) =>
    `url(${getImage($isDarkModeOn ? darkConfettiURL : lightConfettiURL)})`};
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-size: cover;
  border-radius: ${Spacings.SPACING_3B};
  position: relative;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const Tick = styled.img`
  user-drag: none;
  user-select: none;
  position: absolute;
  top: 129px;
  left: 233px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    left: 40%;
  }
`;

const UnlockedText = styled(H2)`
  text-align: center;
  width: 532px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    line-height: ${Fonts.HEADER_LINE_HEIGHT};
  }
`;

const BottomImage = styled.img`
  user-drag: none;
  user-select: none;
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

const LINK_TEXT = "Don't have one?";
const DEFAULT_CODE_PRE_TEXT = 'Try the code ';
const PLACE_HOLDER_TEXT = 'Enter Invite code';
const BUTTON_LABEL_TEXT = 'Unlock';
const UNLOCK_HEADER_TEXT = 'Enter an invite code to unlock your free plan';
const GREEN = 'green';
const FILLED = 'filled';
const DISABLED = 'disabled';
const TEXT = 'text';
const HASH = '#';
const TICK_SIZE = '66px';
const UNLOCKED_MESSAGE = '🙌 Awesome, you have unlocked your free plan!';
const BLANK_STRING = '';
const BOTTOM_IMG_WIDTH = '266px';
const BOTTOM_IMG_HEIGHT = '117px';

const FreePlanUnlock = ({
  drawerState,
  closeDrawerHandler,
  objectType,
  objectUid,
  callBack,
  isDummyUID
}) => {
  const isDarkModeOn = useDarkModeStatus(getIsDarkModeOn);

  const { refetchMyInfo } = useMyInfo();
  const me = useMyInfo(getMe);
  const { selectedGoal: goalDetails } = useSelectedGoal();

  const [inviteCode, setInviteCode] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [enableButton, setEnableButton] = useState(false);
  const [showDefaultCode, setShowDefaultCode] = useState(false);
  const [unlockedStat, setUnlockedStat] = useState(false);

  useEffect(() => {
    if (drawerState) {
      sendFreeUnlockDrawerDisplayEvent(
        goalDetails?.uid,
        goalDetails?.name,
        me.uid,
        me.username,
        objectType,
        objectUid
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerState]);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setInviteCode(value.toUpperCase());
    setEnableButton(!!value.length);
  };

  const sendSegmentEvent = (eventName) => {
    segment.track(eventName, {
      fpu_invite_code: inviteCode,
      object_type: objectType,
      object_uid: objectUid
    });
  };

  const sendUnlockEvent = (success) => {
    sendFreePlanUnlockEvent(
      goalDetails?.uid,
      goalDetails?.name,
      me.uid,
      me.username,
      objectType,
      objectUid,
      inviteCode,
      success
    );
    if (success) {
      sendSegmentEvent(FPU_UNLOCKED);
    }
  };

  const handleUnlock = async () => {
    sendSegmentEvent(FPU_UNLOCK_CLICKED);
    const payload = {
      referral_code: inviteCode,
      object_type: objectType,
      object_uid: objectUid
    };
    if (isDummyUID) {
      payload.is_dummy_uid = true;
    }
    const unlockFreePlanResp = await unlockFreePlan(payload);
    if (unlockFreePlanResp?.error) {
      sendUnlockEvent();
      setErrMsg(unlockFreePlanResp?.error?.message);
    } else if (unlockFreePlanResp) {
      sendUnlockEvent(true);
      // Refetch me data
      refetchMyInfo();
      setUnlockedStat(true);
      if (callBack) {
        callBack();
      }
    }
  };

  const handleShowDefaultCode = (e) => {
    e.preventDefault();
    sendSegmentEvent(FPU_SHOW_DEFAULT);
    setShowDefaultCode(true);
  };

  return (
    <Drawer
      drawerState={drawerState}
      onClose={() => {
        setInviteCode(null);
        setErrMsg(null);
        setEnableButton(false);
        setShowDefaultCode(false);
        closeDrawerHandler();
      }}
    >
      <Grid>
        <Wrapper>
          {unlockedStat ? (
            <>
              <ConfettiCont $isDarkModeOn={isDarkModeOn}>
                <Tick
                  src={getImage('unlockplan/tick.png')}
                  width={TICK_SIZE}
                  height={TICK_SIZE}
                  alt={BLANK_STRING}
                />
              </ConfettiCont>
              <UnlockedText color="var(--color-text-primary)">
                {UNLOCKED_MESSAGE}
              </UnlockedText>
            </>
          ) : (
            <>
              <H2 color="var(--color-text-primary)">{UNLOCK_HEADER_TEXT}</H2>
              <StyledTextField
                type={TEXT}
                value={inviteCode}
                readOnly={false}
                required
                fullWidth
                autoFocus
                placeholder={PLACE_HOLDER_TEXT}
                onChange={handleChange}
              />
              {errMsg && (
                <ErrorMsgText color="var(--color-i-red)">{errMsg}</ErrorMsgText>
              )}
              <BottomWrap>
                <UnlockButton
                  label={BUTTON_LABEL_TEXT}
                  onClick={handleUnlock}
                  color={GREEN}
                  variant={enableButton ? FILLED : DISABLED}
                  dataAnalytics="unlock-free-plan"
                />
                <DefaultCodeTextWrapper>
                  {showDefaultCode ? (
                    <>
                      <DefaultCodeText color="var(--color-text-secondary)">
                        {DEFAULT_CODE_PRE_TEXT}
                        <b>{PLATFORM_UNLOCK_CODE}</b>
                      </DefaultCodeText>
                    </>
                  ) : (
                    <UnderlineLink
                      color="var(--color-text-secondary)"
                      href={HASH}
                      onClick={handleShowDefaultCode}
                    >
                      <LinkText color="var(--color-text-secondary)">
                        {LINK_TEXT}
                      </LinkText>
                    </UnderlineLink>
                  )}
                </DefaultCodeTextWrapper>
              </BottomWrap>
            </>
          )}
        </Wrapper>
      </Grid>
      {!unlockedStat && (
        <BottomImage
          src={getImage('unlockplan/curtain-graphic.svg')}
          width={BOTTOM_IMG_WIDTH}
          height={BOTTOM_IMG_HEIGHT}
          alt={BLANK_STRING}
        />
      )}
    </Drawer>
  );
};

FreePlanUnlock.propTypes = {
  closeDrawerHandler: PropTypes.func.isRequired,
  drawerState: PropTypes.bool.isRequired,
  objectType: PropTypes.number.isRequired,
  objectUid: PropTypes.string.isRequired,
  callBack: PropTypes.func,
  isDummyUID: PropTypes.bool
};

FreePlanUnlock.defaultProps = {
  callBack: null,
  isDummyUID: false
};

export default FreePlanUnlock;
