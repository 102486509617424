import styled from '@emotion/styled';
import PropTypes from 'prop-types';
// import { getImage } from '@utils/imageHelper';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import LeftStickyColumn from './LeftStickyColumn';
import BlocksWrapper from './BlocksWrapper';

const RightColumn = styled.div`
  flex: 2;
  margin-left: 160px;
  @media only screen and (max-width: 540px) {
    margin: 0;
  }
`;

const StepBlock = styled.div`
  background-color: var(--color-i-white);
  display: flex;
  align-items: self-start;
  box-shadow: ${boxShadowGenerator()};
  border-radius: 8px;
  padding: 32px 80px 32px 40px;
  margin-bottom: 16px;
  @media only screen and (max-width: 540px) {
    padding: 16px;
    text-align: left;
  }
`;

const PointImage = styled.img`
  height: 48px;
  width: 48px;
  margin-right: 32px;
  @media only screen and (max-width: 540px) {
    height: 40px;
    width: 40px;
    margin-right: 16px;
  }
`;

const StarPoint = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: var(--color-i-black);
  margin-bottom: 12px;
  @media only screen and (max-width: 540px) {
    font-size: 16px;
    text-align: left;
  }
`;

const SubPoint = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--color-i-black);
  @media only screen and (max-width: 540px) {
    font-size: 10px;
    text-align: left;
  }
`;

const StarPoints = ({ dataSet, enrollNow }) => {
  const { resources: points = [], title, subTitle } = dataSet.starPoints || {};
  return (
    <BlocksWrapper>
      <LeftStickyColumn
        icon="star"
        heading={title}
        subheading={subTitle}
        enrollNow={enrollNow}
      />
      <RightColumn>
        {points.map((point, index) => {
          return (
            <StepBlock key={`point-${index + 1}`}>
              <PointImage src={point.urls?.default} alt="" />
              <div>
                <StarPoint>{point.title}</StarPoint>
                <SubPoint>{point.subTitle}</SubPoint>
              </div>
            </StepBlock>
          );
        })}
      </RightColumn>
    </BlocksWrapper>
  );
};

export default StarPoints;

StarPoints.propTypes = {
  enrollNow: PropTypes.func.isRequired,
  dataSet: PropTypes.objectOf(PropTypes.any).isRequired
};
