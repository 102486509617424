import { Fragment } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import LeftStickyColumn from './LeftStickyColumn';
import BlocksWrapper from './BlocksWrapper';

const RightColumn = styled.div`
  flex: 2;
  margin-left: 160px;
  @media only screen and (max-width: 540px) {
    margin-left: 0px;
  }
`;

const StepBlock = styled.div`
  background-color: var(--color-i-white);
  box-shadow: ${boxShadowGenerator()};
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 16px;
  @media only screen and (max-width: 540px) {
    padding: 16px;
    text-align: left;
  }
`;

const Question = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: var(--color-i-black);
  margin-bottom: 12px;
  @media only screen and (max-width: 540px) {
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

const Answer = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--color-i-black);
  @media only screen and (max-width: 540px) {
    font-size: 12px;
  }
`;

const Subtopic = styled(Answer)`
  font-weight: bold;
  margin-top: 16px;
`;

const ProdigyFAQs = ({ dataSet, enrollNow }) => {
  const renderAnswer = (answer, link) => {
    if (typeof answer === 'string') {
      if (link) {
        return (
          <Answer>
            <span>
              {answer}
              <a href={link.href} target="_blank" rel="noreferrer">
                {link.text}
              </a>
            </span>
          </Answer>
        );
      }
      return <Answer>{answer}</Answer>;
    }
    return answer.map((topic) => {
      return (
        <Fragment key={topic.subtopic}>
          <Subtopic>{topic.subtopic}</Subtopic>
          {topic.info.map((info) => {
            return <Answer key={topic.subtopic}>{info}</Answer>;
          })}
        </Fragment>
      );
    });
  };

  const renderFAQs = () => {
    return dataSet.faqs.map((faq, index) => {
      return (
        <StepBlock key={`faq-${index + 1}`}>
          <Question>{faq.question}</Question>
          {renderAnswer(faq.answer, faq.link)}
        </StepBlock>
      );
    });
  };

  return (
    <BlocksWrapper>
      <LeftStickyColumn
        icon="faq"
        heading="Frequently asked questions"
        subheading="Haven’t found an answer and need help? Email us on help@unacademy.com"
        enrollNow={enrollNow}
      />
      <RightColumn>{renderFAQs()}</RightColumn>
    </BlocksWrapper>
  );
};

export default ProdigyFAQs;

ProdigyFAQs.propTypes = {
  enrollNow: PropTypes.func.isRequired,
  dataSet: PropTypes.objectOf(PropTypes.any).isRequired
};
