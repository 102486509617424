import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import H3 from '@base/H3/H3';
import H6 from '@base/H6/H6';
import {
  Spacings,
  PRIMARY_BORDER_WIDTH,
  SOLID_BORDER_STYLE,
  mobile
} from '@styles/index';
import Facebook from '@icon/Facebook';
import Twitter from '@icon/Twitter';
import useShare from './useShare';

const Wrapper = styled.div`
  padding: ${Spacings.SPACING_10B} ${Spacings.SPACING_12B};
  text-align: center;
  @media only screen and (max-width: ${mobile.maxWidth}) {
    padding: ${Spacings.SPACING_4B};
  }
`;

const ShareLink = styled.div`
  display: grid;
  grid-template: 1fr / 1fr max-content;
  grid-column-gap: ${Spacings.SPACING_4B};
  width: 464px;
  padding: ${Spacings.SPACING_4B} ${Spacings.SPACING_6B};
  border-radius: ${Spacings.SPACING_2B};
  border: ${PRIMARY_BORDER_WIDTH} ${SOLID_BORDER_STYLE} var(--color-divider);
  @media only screen and (max-width: ${mobile.maxWidth}) {
    width: 100%;
    padding: ${Spacings.SPACING_4B};
  }
`;

const Copy = styled(H6)`
  cursor: pointer;
  @media only screen and (max-width: ${mobile.maxWidth}) {
    font-size: 12px;
  }
`;

const Flex = styled.div`
  display: flex;
  margin: ${Spacings.SPACING_6B} auto;
  justify-content: center;
`;

const Link = styled(H6)`
  color: var(--color-text-primary);
  @media only screen and (max-width: ${mobile.maxWidth}) {
    font-size: 12px;
    white-space: nowrap;
  }
`;

export const FB = styled(Facebook)`
  margin-right: ${Spacings.SPACING_3B};
  border-radius: ${Spacings.SPACING_2B};
  background: ${({ $iconBackground }) =>
    $iconBackground || 'var(--color-divider)'};
  cursor: pointer;
  & path {
    fill: ${({ $iconColor }) => $iconColor};
  }
`;

export const Tweet = styled(Twitter)`
  margin-right: ${Spacings.SPACING_3B};
  border-radius: ${Spacings.SPACING_2B};
  background: ${({ $iconBackground }) =>
    $iconBackground || 'var(--color-divider)'};
  cursor: pointer;
  & path {
    fill: ${({ $iconColor }) => $iconColor};
  }
`;

export const LinkContainer = ({ link, copied, onCopy, copyColor }) => (
  <ShareLink>
    <Link lineClamp={1}>{link}</Link>
    {navigator.clipboard && (
      <Copy
        color={copied ? 'var(--color-text-primary)' : copyColor}
        onClick={onCopy}
      >
        {copied ? 'Copied' : 'COPY'}
      </Copy>
    )}
  </ShareLink>
);

const ShareDialog = ({
  data,
  useDataLink,
  twitterText,
  iconBackground,
  iconColor,
  copyColor
}) => {
  const { name = '' } = data || {};
  const link = useDataLink ? data.permalink : window.location.href;

  const { copied, handleFBShare, handleTwitterShare, copyToClipboard } =
    useShare({ title: name, text: twitterText, link });

  return (
    <Wrapper>
      <H3 color="var(--color-text-primary)">Share via</H3>
      <Flex>
        <FB
          $iconBackground={iconBackground}
          $iconColor={iconColor}
          size="40px"
          onClick={handleFBShare}
        />
        <Tweet
          $iconBackground={iconBackground}
          $iconColor={iconColor}
          size="40px"
          onClick={handleTwitterShare}
        />
      </Flex>
      <LinkContainer
        link={link}
        copied={copied}
        copyColor={copyColor}
        onCopy={copyToClipboard}
      />
    </Wrapper>
  );
};

export default ShareDialog;

ShareDialog.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  useDataLink: PropTypes.bool,
  twitterText: PropTypes.string,
  iconBackground: PropTypes.string,
  iconColor: PropTypes.string,
  copyColor: PropTypes.string
};

ShareDialog.defaultProps = {
  data: {},
  iconBackground: 'var(--color-divider)',
  iconColor: 'var(--color-base-fill)',
  useDataLink: false,
  twitterText: null,
  copyColor: 'var(--color-i-green)'
};
