/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useEffect, useRef } from 'react';

const VideoLazyLoad = ({ children }) => {
  const lazyVideoObserver = useRef(null);
  useEffect(() => {
    const lazyVideos = [].slice.call(
      document.querySelectorAll('video.lazy-video')
    );
    if ('IntersectionObserver' in window) {
      lazyVideoObserver.current = new IntersectionObserver((entries) => {
        if (entries && entries.length > 0) {
          entries.forEach((video) => {
            if (video && video.isIntersecting) {
              const targetChildren = video?.target?.children;
              if (targetChildren) {
                for (const source in targetChildren) {
                  const videoSource = targetChildren[source];
                  if (
                    typeof videoSource.tagName === 'string' &&
                    videoSource.tagName === 'SOURCE'
                  ) {
                    videoSource.src = videoSource.dataset.src;
                  }
                }
              }
              video.target.load();
              video.target.classList.remove('lazy-video');
              lazyVideoObserver.current.unobserve(video.target);
            }
          });
        }
      });

      lazyVideos.forEach((lazyVideo) => {
        lazyVideoObserver.current.observe(lazyVideo);
      });

      return () => {
        if (lazyVideoObserver.current) lazyVideoObserver.current.disconnect();
      };
    }
  });
  return children;
};

export default VideoLazyLoad;
