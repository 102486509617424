/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/jsx-filename-extension */

const imageSourcePropGenerator = (src) => ({ srcSet: src });
const videoSourcePropGenerator = (src, lazyLoadVideo) => {
  const key = lazyLoadVideo ? 'data-src' : 'src';
  return {
    [key]: src
  };
};
// eslint-disable-next-line import/prefer-default-export
export const getSourcesFromUrlMap = (
  urlMap,
  isImage = false,
  lazyLoadVideo = false
) => {
  if (!urlMap) return null;
  const sourcePropGenerator = isImage
    ? imageSourcePropGenerator
    : videoSourcePropGenerator;
  return (
    <>
      {!urlMap.default &&
        Object.keys(urlMap).map((mimeType) => {
          const props = sourcePropGenerator(
            urlMap[mimeType] || '',
            lazyLoadVideo
          );
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <source key={mimeType} {...props} type={mimeType} />
          );
        })}
      {urlMap.default && (
        <source
          key={urlMap.defaultMimeType}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sourcePropGenerator(urlMap.default || '', lazyLoadVideo)}
          type={urlMap.defaultMimeType}
        />
      )}
      {isImage && <img alt="-" />}
    </>
  );
};
