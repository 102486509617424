/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';
import { useState } from 'react';
import CountUp from 'react-countup';
import KeyboardArrowDown from '@icon/KeyboardArrowDown';
import Button from '@base/Button/Button';
import { getImage } from '@utils/imageHelper';
import textHelper from '@utils/textHelper';
import { breakpoints } from '@styles/index';
import useTimeout from '@hooks/useTimeout';
import ImageCarousel from '@comp/ImageCarousel/ImageCarousel';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import DateTimer from './DateTimer';

const Header = styled.section`
  position: relative;
  overflow: hidden;
  height: 628px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(229, 244, 255, 0.44) 100%
  );
  @media only screen and (max-width: ${breakpoints.mobile}) {
    max-height: 530px;
  }
`;

const Content = styled.div`
  max-width: ${({ $showBanners }) => ($showBanners ? '384px' : '750px')};
  padding-top: 64px;
  text-align: ${({ $showBanners }) => ($showBanners ? 'left' : 'center')};
  margin: ${({ $showBanners }) => ($showBanners ? 'unset' : 'auto')};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    text-align: center;
    margin: auto;
  }
`;

/* const FrameLeft = styled.img`
  position: absolute;
  display: none;
  left: 0px;
  bottom: 0px;
  transform: ${({ mounted }) => `translateX(${mounted ? 0 : -300}px)`};
  opacity: ${({ mounted }) => (mounted ? 1 : 0)};
  transition: transform 400ms ease-out, opacity 400ms ease-out;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    max-height: 190px;
  }
`;

const FrameRight = styled.img`
  position: absolute;
  display: none;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    display: none;
  }
  right: 0px;
  bottom: 0px;
  transform: ${({ mounted }) => `translateX(${mounted ? 0 : 300}px)`};
  opacity: ${({ mounted }) => (mounted ? 1 : 0)};
  transition: transform 800ms ease-out, opacity 800ms ease-out;
`; */

const MainBg = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

const BottomPencilsIls = styled.img`
  position: absolute;
  bottom: 0;
  right: ${({ $showBanners }) => (!$showBanners ? '38%' : 'unset')};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    right: 0;
  }
`;

const Heading = styled.span`
  font-weight: bold;
  font-size: ${({ $showBanners }) => ($showBanners ? '36px' : '48px')};
  line-height: 120%;
  color: var(--color-i-black);
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 32px;
  }
`;

const Amount = styled(CountUp)`
  font-weight: bold;
  font-size: ${({ $showBanners }) => ($showBanners ? '32px' : '48px')};
  line-height: 120%;
  color: var(--color-i-green);
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 32px;
  }
`;

const SubText = styled.p`
  font-weight: normal;
  font-size: ${({ $showBanners }) => ($showBanners ? '16px' : '24px')};
  line-height: 150%;
  text-align: ${({ $showBanners }) => ($showBanners ? 'left' : 'center')};
  margin: auto;
  margin-top: 30px;
  max-width: 720px;
  color: var(--color-i-black);
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 16px;
    margin: 24px 16px;
    text-align: center;
  }
`;

const keyFrameArrow = keyframes`
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
`;

const animationArrow = () =>
  css`
    ${keyFrameArrow} 800ms infinite  alternate
  `;

const ArrowContainer = styled.div`
  animation: ${({ mounted }) => (mounted ? animationArrow : 'initial')};
  margin-top: 24px;
  margin-bottom: 88px;
  animation-delay: ${({ mounted }) => (mounted ? 1200 : 0)}ms;
  animation-timing-function: ease-in-out;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin-bottom: 48px;
  }
`;

const EnrollButton = styled(Button)`
  margin: ${({ $showBanners }) => ($showBanners ? 'unset' : 'auto')};
  margin-top: 44px;
  height: 60px;
  font-size: 20px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    height: 38px;
    width: 100px;
    font-size: 14px;
    margin: auto;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1136px;
  margin: auto;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    max-width: 100%;
    /* flex-direction: column; */
  }
`;

const CarouselWrapper = styled.div`
  width: 600px;
  height: 400px;
  margin: 72px 0;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

const MWCarouselWrapper = styled.div`
  display: none;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    /* position: relative; */
    display: block;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const WebDapeWrapper = styled.div`
  display: block;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    /* position: relative; */
    display: none;
  }
`;
const BannerImage = styled.img`
  width: inherit;
  height: 400px;
  border-radius: 8px;
  box-shadow: ${boxShadowGenerator({})};
`;

const currencySymbol = textHelper.currencySymbolMap('INR');
const countUpFormat = (num) => textHelper.commasIndianFormat(num);

const ProdigyHeader = ({ dataSet, enrollNow, isEnrolled }) => {
  const [mounted, setMounted] = useState(false);
  useTimeout(() => setMounted(true), 0);

  const { header, carousels } = dataSet;
  const headerData = header || {};

  const renderCarousel = (isMW) => {
    return (
      <ImageCarousel>
        {carousels.map((banner, i) => {
          return (
            <BannerImage
              key={`banner-${banner.id}`}
              src={isMW ? banner.mwImage : banner.webImage}
              alt={`banner-${i}`}
              onClick={
                banner.permalink ? () => window.open(banner.permalink) : null
              }
            />
          );
        })}
      </ImageCarousel>
    );
  };

  const carouselLength = carousels?.length;

  return (
    <>
      <Header>
        <MainBg src={getImage('prodigy/asset-bg.svg')} alt="background" />
        <Flex>
          <BottomPencilsIls
            src={getImage('prodigy/pencils.svg')}
            alt="bg-illustrations"
            $showBanners={!!carouselLength}
          />
          <Content $showBanners={!!carouselLength}>
            <Heading $showBanners={!!carouselLength}>
              {headerData.title}
              {headerData.isAmountAnim && (
                <Amount
                  $showBanners={!!carouselLength}
                  start={headerData.start}
                  end={headerData.end}
                  formattingFn={(num) =>
                    `${currencySymbol}${countUpFormat(num)}`
                  }
                  duration={1}
                />
              )}
            </Heading>
            <SubText $showBanners={!!carouselLength}>
              {headerData.subTitle}
            </SubText>
            <EnrollButton
              $showBanners={!!carouselLength}
              label={isEnrolled ? 'Enrolled' : 'Enroll now'}
              onClick={enrollNow}
            />
          </Content>
          {carouselLength > 0 ? (
            <CarouselWrapper>{renderCarousel()}</CarouselWrapper>
          ) : null}
          <WebDapeWrapper>
            <DateTimer
              mounted={mounted}
              headerData={headerData}
              showBanners={!!carouselLength}
            />
          </WebDapeWrapper>
        </Flex>
      </Header>
      {carousels ? (
        <MWCarouselWrapper>
          {renderCarousel(true)}
          <DateTimer
            mounted={mounted}
            headerData={headerData}
            showBanners={!!carouselLength}
          />
        </MWCarouselWrapper>
      ) : null}
      <ArrowContainer mounted={mounted}>
        <KeyboardArrowDown size="24px" color="var(--color-base-fill)" />
      </ArrowContainer>
    </>
  );
};

export default ProdigyHeader;
