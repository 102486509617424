/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useState, useEffect, Children, useRef } from 'react';
import { Slide } from '@material-ui/core';
import IndicatorItem from './IndicatorItem';
import { Spacings } from '../../../styleConstants';

const Indicators = styled.div`
  width: 100%;
  margin-top: ${Spacings.SPACING_6B};
  justify-content: center;
  display: flex;
`;
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
const CarouselItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ hidden }) => hidden && 'display: none;'}
`;

const ImageCarousel = ({ autoPlay, interval, children, color, className }) => {
  const items = Children.toArray(children);
  const [active, setActive] = useState(0);
  const timer = useRef(null);
  const next = () => {
    const nextIndex = active + 1 > items.length - 1 ? 0 : active + 1;
    setActive(nextIndex);
  };
  const stop = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };
  const start = () => {
    if (autoPlay) timer.current = setTimeout(next, interval);
  };
  const reset = () => {
    stop();
    if (autoPlay) start();
  };
  const pressIndicator = (index) => {
    stop();
    setActive(index);
  };

  useEffect(() => {
    if (items.length < 2) {
      stop();
      setActive(0);
    } else {
      start();
    }
    return () => stop();
  }, [items.length]);

  useEffect(() => {
    timer.current = setTimeout(next, interval);
  }, [active, interval, next]);

  useEffect(() => {
    reset();
  }, [autoPlay, interval]);

  const renderIndicators = () => {
    const indicators = items.map((item, i) => {
      return (
        <IndicatorItem
          key={`indicator_${i}`}
          active={i === active}
          index={i}
          onClick={() => pressIndicator(i)}
          color={color}
          className={`indicator-${className}`}
        />
      );
    });
    return <Indicators className={className}>{indicators}</Indicators>;
  };

  return (
    <Wrapper onMouseEnter={stop} onMouseOut={reset}>
      {items.map((item, index) => {
        const isActive = index === active;
        return (
          <Slide
            direction="left"
            in={isActive}
            timeout={500}
            key={`CarouselItem_${index}_fade`}
          >
            <CarouselItem key={`CarouselItem_${index}`} hidden={!isActive}>
              {item}
            </CarouselItem>
          </Slide>
        );
      })}
      {renderIndicators()}
    </Wrapper>
  );
};

export default ImageCarousel;

ImageCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  autoPlay: PropTypes.bool,
  interval: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
};

ImageCarousel.defaultProps = {
  autoPlay: true,
  interval: 4000,
  color: '',
  className: ''
};
