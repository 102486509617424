import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { getSourcesFromUrlMap } from '@utils/resourceHelper';
import LeftStickyColumn from './LeftStickyColumn';
import BlocksWrapper from './BlocksWrapper';

const RightColumn = styled.div`
  flex: 2;
  margin-left: 160px;
  @media only screen and (max-width: 540px) {
    margin-left: 0px;
  }
  div:not(:last-child) {
    margin-bottom: 168px;
    @media only screen and (max-width: 540px) {
      margin-bottom: 40px;
    }
  }
`;

const StepBlock = styled.div`
  margin-bottom: 16px;
  text-align: center;
`;

const AboutVideo = styled.video`
  width: 580px;
  height: 340px;
  @media only screen and (max-width: 540px) {
    width: 100%;
    height: unset;
  }
`;

const AboutPoint = styled.p`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: var(--color-i-black);
  margin-top: 32px;
  margin-bottom: 24px;
  @media only screen and (max-width: 540px) {
    font-size: 20px;
    margin-top: 24px;
    margin-bottom: 12px;
  }
`;

const AboutSubpoint = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-i-black);
  max-width: 408px;
  margin: auto;
  @media only screen and (max-width: 540px) {
    font-size: 14px;
  }
`;

const AboutProdigy = ({ dataSet, enrollNow }) => {
  const {
    resources: aboutPoints = [],
    title,
    subTitle
  } = dataSet.aboutProdigy || {};
  return (
    <BlocksWrapper>
      <LeftStickyColumn
        icon="badge"
        heading={title}
        subheading={subTitle}
        enrollNow={enrollNow}
      />
      <RightColumn>
        {aboutPoints.map((point, i) => {
          return (
            <StepBlock key={`about-${i + 1}`}>
              <AboutVideo loop autoPlay playsInline muted>
                {getSourcesFromUrlMap(point.urls)}
              </AboutVideo>
              <AboutPoint>{point.title}</AboutPoint>
              <AboutSubpoint>{point.subTitle}</AboutSubpoint>
            </StepBlock>
          );
        })}
      </RightColumn>
    </BlocksWrapper>
  );
};

export default AboutProdigy;

AboutProdigy.propTypes = {
  enrollNow: PropTypes.func.isRequired,
  dataSet: PropTypes.objectOf(PropTypes.any).isRequired
};
