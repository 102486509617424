import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import H4 from '@base/H4/H4';
import P1 from '@base/P1/P1';
import P2 from '@base/P2/P2';
import Button from '@base/Button/Button';
import dateHelper from '@utils/dates';
import { Spacings, Fonts } from '@styles/index';
import { getImage } from '@utils/imageHelper';
import { Live } from './ProdigyTestCard';

const Wrapper = styled.div`
  border-radius: ${Spacings.SPACING_2B};
  background-color: var(--color-base-1);
  width: 100%;
  text-align: left;
`;

const Header = styled.div`
  padding: ${Spacings.SPACING_7B} ${Spacings.SPACING_7B} ${Spacings.SPACING_5B}
    ${Spacings.SPACING_5B};
  border-top-left-radius: ${Spacings.SPACING_2B};
  border-top-right-radius: ${Spacings.SPACING_2B};
  position: relative;
`;

const HeaderBg = styled.div`
  position: absolute;
  border-top-left-radius: ${Spacings.SPACING_2B};
  border-top-right-radius: ${Spacings.SPACING_2B};
  background-image: url('${getImage('prodigy/play-lines-bg.svg')}'),
    linear-gradient(
      106.39deg,
      var(--color-i-white) 29.85%,
      var(--color-i-blue-8) 100%
    );
  background-position: top right;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.4;
  top: 0;
  left: 0;
  background-blend-mode: color-burn;
`;

const HeaderContent = styled.div`
  position: relative;
  z-index: 2;
`;

const Topics = styled(P1)`
  font-weight: ${Fonts.SEMIBOLD};
`;

const Title = styled(H4)`
  margin-top: ${Spacings.SPACING_1B};
`;

const Author = styled(P1)`
  margin-top: ${Spacings.SPACING_1B};
  line-height: 130%;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${Spacings.SPACING_4B};
`;

const ContentContainer = styled.div`
  padding: ${Spacings.SPACING_6B} ${Spacings.SPACING_6B} ${Spacings.SPACING_5B}
    ${Spacings.SPACING_5B};
`;

const Description = styled(P2)`
  line-height: 167%;
  padding-right: ${Spacings.SPACING_6B};
`;

const Divider = styled.div`
  height: 1px;
  background-color: var(--color-divider);
  margin-top: ${Spacings.SPACING_11B};
  margin-bottom: ${Spacings.SPACING_5B};
`;

const BottomContent = styled.div`
  display: grid;
  align-items: center;
  grid-template: 1fr / 1fr max-content;
`;

const StyledBtn = styled(Button)`
  width: 136px;
`;

const Time = styled(P1)`
  line-height: ${Fonts.PARA_LINE_HEIGHT};
`;

const LiveDot = styled(Live)`
  margin-top: 0px;
`;

const getInfoString = (lesson, isLive) => {
  const separator = '\u00a0\u00a0•\u00a0\u00a0';
  return `${isLive ? `Live class${separator}` : ''}${lesson.duration}`;
};

const ProdigyClassCard = ({ data: lesson, onClickEnroll, isEnrolled }) => {
  const dateNow = new Date();
  const isLive =
    dateHelper.compareDates(dateNow, lesson.startsAt) === -1 &&
    dateHelper.compareDates(dateNow, lesson.endsAt) === 1;

  const isCompleted = dateHelper.compareDates(dateNow, lesson.endsAt) === -1;
  const shouldStart = !isLive && !isCompleted;
  const handleOnClickEnroll = () => {
    if (typeof onClickEnroll === 'function') {
      onClickEnroll(!lesson.isEnrolled, lesson.testSeriesUid, lesson);
    }
  };
  const watchClass = () => {
    window.open(lesson.permalink);
  };
  const label =
    ((isCompleted || isLive) && 'Watch Now') ||
    (isEnrolled && 'Enrolled') ||
    'Enroll Now';
  const clickHandler =
    ((isCompleted || isLive) && watchClass) ||
    (isEnrolled && (() => {})) ||
    handleOnClickEnroll;
  return (
    <Wrapper>
      <Header>
        <HeaderBg />
        <HeaderContent>
          <Topics color="var(--color-i-blue-7)">{lesson.topics}</Topics>
          <Title>{lesson.title}</Title>
          <Author>{`By ${lesson.authorName}`}</Author>
          <Info>
            {isLive && <LiveDot />}
            <P1>{getInfoString(lesson, isLive)}</P1>
          </Info>
        </HeaderContent>
      </Header>
      <ContentContainer>
        <Description color="var(--color-text-secondary)">
          {lesson.description}
        </Description>
        <Divider />
        <BottomContent>
          <Time>{lesson.time}</Time>
          <StyledBtn
            label={label}
            onClick={clickHandler}
            variant={shouldStart && isEnrolled ? 'hollow' : 'filled'}
            color={shouldStart && isEnrolled ? 'black' : 'green'}
          />
        </BottomContent>
      </ContentContainer>
    </Wrapper>
  );
};

ProdigyClassCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onClickEnroll: PropTypes.func.isRequired,
  isEnrolled: PropTypes.bool
};

ProdigyClassCard.defaultProps = {
  isEnrolled: false
};

export default ProdigyClassCard;
