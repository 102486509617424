import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import { getSourcesFromUrlMap } from '@utils/resourceHelper';
import LeftStickyColumn from './LeftStickyColumn';
import BlocksWrapper from './BlocksWrapper';

const RightColumn = styled.div`
  flex: 2;
  div:not(:last-child) {
    margin-bottom: 88px;
    @media only screen and (max-width: 540px) {
      margin-bottom: 16px;
    }
  }
`;

const Step = styled.div`
  margin-left: 120px;
  display: flex;
  position: relative;
  @media only screen and (max-width: 540px) {
    margin: 0;
    margin-left: 16px;
  }
`;

const StepNumber = styled.p`
  font-weight: bold;
  font-size: 120px;
  line-height: 120%;
  color: var(--color-divider);
  z-index: 1;
  min-width: 64px;
  position: absolute;
  left: ${({ step }) => (step === 1 ? '-32px' : '-44px')};
  @media only screen and (max-width: 540px) {
    font-size: 60px;
    min-width: 40px;
    left: -24px;
    top: 15%;
  }
`;

const StepBlock = styled.div`
  height: 160px;
  background-color: var(--color-i-white);
  display: flex;
  align-items: center;
  box-shadow: ${boxShadowGenerator()};
  border-radius: 8px;
  padding-right: 80px;
  @media only screen and (max-width: 540px) {
    height: 109px;
    padding: 16px;
  }
`;

const StepGif = styled.video`
  height: 160px;
  width: 160px;
  margin-right: 40px;
  @media only screen and (max-width: 540px) {
    height: 100px;
    width: 100px;
    margin-right: 16px;
    margin-left: -16px;
  }
`;

const StepText = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 12px;
  color: var(--color-i-black);
  @media only screen and (max-width: 540px) {
    font-size: 16px;
    text-align: left;
  }
`;

const StepSubText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--color-i-black);
  @media only screen and (max-width: 540px) {
    font-size: 10px;
    text-align: left;
  }
`;

const HowToCrack = ({ enrollNow, dataSet }) => {
  const { howToCrack } = dataSet;
  const data = howToCrack || {};
  const { resources: steps = [] } = data;
  return (
    <BlocksWrapper>
      <LeftStickyColumn
        icon="how-to"
        heading={data.title}
        subheading={data.subTitle}
        enrollNow={enrollNow}
      />
      <RightColumn>
        {steps.map((step, index) => {
          return (
            <Step key={`step-${index + 1}`}>
              <StepNumber step={index + 1}>{index + 1}</StepNumber>
              <StepBlock>
                <StepGif loop muted playsInline autoPlay>
                  {getSourcesFromUrlMap(step.urls)}
                </StepGif>
                <div>
                  <StepText>{step.title}</StepText>
                  <StepSubText>{step.subTitle}</StepSubText>
                </div>
              </StepBlock>
            </Step>
          );
        })}
      </RightColumn>
    </BlocksWrapper>
  );
};

export default HowToCrack;

HowToCrack.propTypes = {
  enrollNow: PropTypes.func.isRequired,
  dataSet: PropTypes.objectOf(PropTypes.any).isRequired
};
