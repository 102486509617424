import styled from '@emotion/styled';
import { getImage } from '@utils/imageHelper';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import BlocksWrapper from './BlocksWrapper';

const Wrapper = styled(BlocksWrapper)`
  height: 444px;
  background: var(--color-i-white);
  box-shadow: ${boxShadowGenerator()};
  border-radius: 8px;
  margin-bottom: 160px;
  padding: 0px 116px 0px 80px;
  @media only screen and (max-width: 540px) {
    flex-direction: column-reverse;
    height: auto;
    width: 92%;
    padding: 24px;
    margin: auto 16px;
    margin-bottom: 80px;
  }
`;

const LeftColumn = styled.div`
  max-width: 418px;
  margin: auto 0;
`;

const Heading = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  color: var(--color-i-black);
  margin-bottom: 16px;
  @media only screen and (max-width: 540px) {
    font-size: 24px;
  }
`;

const SubHeading = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: var(--color-i-black);
  margin-bottom: 40px;
  @media only screen and (max-width: 540px) {
    font-size: 16px;
  }
`;

const RightColumn = styled.div`
  margin-left: 148px;
  position: relative;
  @media only screen and (max-width: 540px) {
    margin-left: 0px;
  }
`;

const AppImage = styled.img`
  height: 372px;
  width: 382px;
  position: absolute;
  bottom: 0px;
  @media only screen and (max-width: 540px) {
    width: 100%;
    height: unset;
    position: relative;
    margin-bottom: 16px;
  }
`;

const AppBanners = styled.div`
  display: flex;
  a:first-child {
    margin-right: 16px;
  }
`;

const AppBanner = styled.img`
  height: 48px;
  /* width: 162px; */
`;

const AppInstall = () => {
  return (
    <Wrapper>
      <LeftColumn>
        <Heading>Practice on the go</Heading>
        <SubHeading>
          Take mock test and practice anywhere, anytime with the Unacademy app
        </SubHeading>
        <AppBanners>
          <a
            href="https://play.google.com/store/apps/details?id=com.unacademyapp&referrer=utm_source%3DFooter%26utm_medium%3DWebApp%26utm_campaign%3DFooter%26"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AppBanner src={getImage('playStore_resized.svg')} />
          </a>
          <a
            href="https://apps.apple.com/in/app/unacademy-learning-app/id1342565069?referrer%3Dutm_source%253DFooter%2526utm_medium%253DWebApp%2526utm_campaign%253DFooter%2526"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AppBanner src={getImage('appStore_resized.svg')} />
          </a>
        </AppBanners>
      </LeftColumn>
      <RightColumn>
        <AppImage src={getImage('newApp.png')} />
      </RightColumn>
    </Wrapper>
  );
};

export default AppInstall;
